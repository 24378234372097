import React from 'react'
import 'twin.macro'

export default function FactDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"",
        sub_heading:"",
        description:"",
       ...finalData
  } 
    return (
        <div tw="bg-transparent w-full flex justify-center items-center">
            <div tw="py-12 bg-secondary-900  lg:py-0 lg:relative w-11/12 rounded-xl">
                <div tw="mx-auto px-4 w-11/12 sm:w-10/12 sm:px-6 lg:px-0 lg:py-16 lg:max-w-none">
                    <h3 tw="mt-2 text-base text-center font-light text-white">
                        {content.heading}
                    </h3>
                    <div tw="mt-4 text-2xl text-center font-bold text-white" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                </div>
            </div>
        </div>
    )
}
